.select_container{
    max-width: 350px;
}

/* the select will take up space above the select element if the view is blocked by the bottom of the screen
    Added this to showcase the variation in storybook
*/
.dropup{
    margin-top: 500px;
}

select{
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;

    border: 1px solid #cccccc;
    border-bottom: none;

    appearance: none;
    background-image: url("../../images/select.svg");
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    padding-right: 40px;

    cursor: pointer;
}

.select_active{
    color: #007e8d;
    border: 1px solid black;
    border-bottom: none;
}

option{
    padding: 8px;
}

.custom_tip{
    max-width: 300px;
    padding: 5px;
    font-size: .65rem !important;
    animation: animateTool 0.5s;
}

@keyframes animateTool {
    0%{
        opacity: 0;
    }
    75%{
        opacity: 0;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}