.results_container_table{
    border: solid 1px #DDDDDD;
    max-width: 720px;
}

.results_table_header{
    background-color: #f4f4f4;
    max-width: 720px;

    display: flex;
    align-items: center;
    font-size: 12px;

    font-weight: bold;
}

.results_table_subject_col{
    max-width: 414px;
    min-width: 100px;
    flex-grow: 1;
    flex-basis: 0;
    padding: 9px 10px;
}

.results_table_status_col{
    max-width: 85px;
    flex-grow: 1;
    flex-basis: 0;
    padding: 9px 10px;
}

.results_table_date_col{
    max-width: 220px;
    flex-grow: 1;
    flex-basis: 0;
    padding: 9px 10px;
}

.row_even{
    background-color: #F9F9F9;
}

.header_wrapper{
    margin-bottom: 1.0625rem;
}

/* Child header component strong tags not working as they should,
    forcing bold weight
*/
.header_wrapper>div>strong{
    font-weight: bold;
}

@media screen and (max-width: 466px){
    .header_wrapper>div{
        flex-wrap: wrap;
        gap: 0;
    }

    .header_wrapper>div>strong,
    .header_wrapper>div>div,
    .header_wrapper>div>a{
        margin-right: 20px;
    }

    .results_table_status_col{
        max-width: 65px;
    }
    .results_table_subject_col{
        max-width: 434px;
    }
    .results_table_date_col{
        max-width: 150px;
    }
}