.navItem {
    padding: 5px 10px;
    border-bottom: 1px solid #f4f4f4;
}

.navItem .link {
    color: #727272;
    font-size: 14px;
    font-weight: 300;
}

.tipBoxContainer {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear;
}

.hover {
    opacity: 1;
    visibility: visible;
}