.page {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 30px;
}

.header {
  align-self: flex-start;
}

.pageContent {
  display: flex;
  flex-direction: row;
}

.steps {
  padding: 0px 15px;
}

.notices {
  min-width: 250px;
  max-width: 250px;
  padding-left: 30px;
  border-left: 1px solid #dddddd;
  margin-left: 45px;
  margin-right: 15px;
}

h3 {
  font-size: 20px;
  margin-bottom: 30px;
}
