.container {
  overflow-x: auto;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ccc;
}

.table {
  min-width: 100%;
  width: fit-content;
}

.tableHeader {
  display: flex;
  background-color: #e5e5e5;
  padding: 10px;
  width: 100%;
}

.row {
  display: flex;
  padding: 10px;
}

.bottom {
  margin-top: 30px;
}
