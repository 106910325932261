.dashboardAssignmentTable {
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0;
        background-color: #f0f0f0;
        width: 100%;
    }

    .scrollContainer {
        width: auto;
        height: 100%;
        background-color: white;
        border: 1px solid #ccc;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .content {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        font-size: 12px;
        height: 100%;
        margin-right: 5px;
    }


    .columnCheckBox {
        width: 4.12%;
        min-width: 40px;
        text-align: center;
    }
    
    .columnIndex0 {
        width: 15.11%;
        min-width: 150px;
    }
    
    .columnIndex1 {
        width: 9.11%;
        min-width: 90px;
    }
    
    .columnIndex2 {
        width: 9.11%;
        min-width: 90px;
    }
    
    .columnIndex3 {
        width: 9.11%;
        min-width: 90px;
    }
    
    .columnIndex4 {
        width: 15.11%;
        min-width: 150px;
    }
    
    .columnIndex5 {
        width: 12.11%;
        min-width: 120px;
    }
    
    .columnIndex6 {
        width: 12.11%;
        min-width: 120px;
    }
    
    .columnIndex7 {
        width: 8.11%;
        min-width: 80px;
    }
    .row {
        display: flex;
        align-items: center;
    }

    .column {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .rowFilter {
        justify-content: center;
    }

    .columnFilter {
        margin: 0px 10px;
    }

    .assignmentHeaderRow,
    .assignmentTable,
    .assignmentFilterRow {
        border: solid 1px #DDDDDD;
    }

    .videoPopup {
        color: #007e8d;
    }

    .assignmentTable {
        font-size: 12px;
    }

    .assignmentHeaderRow span {
        font-weight: bold;
    }

    .assignmentTutorials span {
        font-size: 16px;
        margin: 0px 10px;
    }

    .assignmentActionsRow {
        margin-bottom: 10px;

        button:hover,
        button:focus,
        .button:hover,
        .button:focus {
            background-color: #007095;
        }

        button.disabled,
        button[disabled],
        .button.disabled,
        .button[disabled] {
            background-color: #008CBA;
            border-color: #007095;
            color: #FFFFFF;
            cursor: default;
            opacity: 0.7;
            box-shadow: none;
        }
    }

    .assignmentActionsRow button {
        font-size: 11px;
        background-color: #008CBA;
    }

    .assignmentActionsRow select {
        margin: 0px 5px !important;
    }

    .assignmentActionsRow label {
        margin: 0px !important;
    }

    .assignmentActionsRow .dashboardSearch {
        justify-content: right;
        width: 100%;
        margin: 0px;
    }

    .assignmentActionsRow .dashboardSearch label {
        padding-bottom: 0px !important;
    }

    .assignmentActionsRow .dashboardSearch input {
        margin: 0px !important;
       
    }

    .assignmentActionsRow .dashboardSearch div{
        padding: 0px !important;
    }

    .assignmentFilterRow {
        border-top: unset;
        padding: 10px;
        background-color: #f4f4f4;
        margin: 0px;
    }
    .assignmentFooter{
        border: unset;
    }

    .assignmentHeader{
        border-bottom: unset;
    }

    .assignmentPaginationRow {
        padding-top: 25px;
    }

    .columnPagination {
        display: unset !important;
        width: 100%;
    }

    .evenRow {
        background-color: #F9F9F9;
    }

    @media (max-width: 767px) {
        .column {
            flex-basis: 100%;
        }
    }

}