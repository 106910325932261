select.itemsPerPage {
    text-transform: none;
    appearance: none !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    padding: 0 50px 0 20px;
    line-height: normal;
    height: 2.3525rem;
    text-indent: 0.01px !important;
    text-overflow: '' !important;
    cursor: pointer !important;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 13px;
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
    margin: 0 5px;
    background: #ffffff url(../../images/select.svg) no-repeat right 20px center !important;
}

.selectLabel {
    font-size: 14px;
    font-weight: 300;
    color: #3d3d3d;
    margin: 10px 0 0;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
}