.container {
  display: flex;
  flex-direction: column;
}

.nav {
  min-width: 220px;
}

.banner {
  width: 80%;
  margin: 0 auto;
}

.contentContainer {
  max-width: 80%;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 90px 0;
  margin: 0 auto;
}

.contentNav {
  flex: 1;
  width: 100%;
}

.children {
  flex: 3;
  width: 100%;
}

.contentNavItem {
  padding: 5px 10px;
}

@media screen and (max-width: 1000px) {
  .banner {
    width: 100%;
  }
}